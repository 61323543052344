import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Chip from '@material-ui/core/Chip';
import {status, statusColor, statusVariant} from '../../fuction';
import {IconButton, Tooltip, Typography} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import TableRow from '@material-ui/core/TableRow';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import {Modal} from './Modal';

const useStyles = makeStyles((theme) => ({
    root: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f5f5f5'
        }
    },
    root_places: {
        cursor: 'pointer',
        backgroundColor: '#fba023',
        '&:hover': {
            backgroundColor: '#fdc26d'
        }
    },
    button: {
        '& *': {
            pointerEvents: 'none'
        }
    }
}))

export const Item = ({item, onUpdate}) => {
    const classes = useStyles();
    const history = useHistory();
    const handleClick = (e) => {
        if (e.target.tagName === 'BUTTON' || e.target.tagName === 'path') return
        history.push(`/detail/${item.uuid}`, {order: item})
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <TableRow key={item.uuid} onClick={handleClick} className={item.place_assembly && item.place_assembly !== "" ? classes.root_places : classes.root}>
                <TableCell>
                    {item.client_name}
                </TableCell>
                <TableCell>
                    {item.status_client && (
                        <Chip
                            variant="default"
                            style={{
                                background: statusVariant(item.status_client),
                                color: statusColor(item.status_client)
                            }}
                            label={status(item.status_client)}
                            size="small"
                        />
                    )}
                </TableCell>
                <TableCell>
                    {item.cabin.name}
                </TableCell>
                <TableCell>
                    {item.brand.name}
                </TableCell>
                <TableCell>
                    {(item.comment || (item.place_assembly && item.place_assembly)) && (
                        <Tooltip title={
                            <React.Fragment>
                                <Typography color="inherit">

                                    {item.comment && item.comment}
                                    {item.place_assembly && item.place_assembly && (
                                        <div>
                                            <strong>Miejsce montażu:</strong> {item.place_assembly}
                                        </div>
                                    )}
                                </Typography>
                            </React.Fragment>
                        } arrow>
                            <IconButton size="small" className={classes.button}>
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </TableCell>
                <TableCell>
                    <IconButton size="small" className={classes.button} onClick={handleClickOpen}>
                        <EditIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            <Modal open={open} handleClose={handleClose} item={item} onUpdate={onUpdate} />
        </>
    )
}