import React, {useState, useEffect} from 'react'
import {
  Paper,
  Typography,
  Container,
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem, FormGroup, FormControlLabel, Checkbox
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'
import {setNoticeError, setNoticeSuccess} from "../../store/notistack";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {callAPI, compareDate, dateInfo, status, statusList, statusListClient} from "../../fuction";
import {setName} from "../../store/currentPage";
import Toolbar from "@material-ui/core/Toolbar";
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: 0,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    // display: 'flex',
    // flexDirection: 'row',
    // flexWrap: 'wrap',
    // alignItems: 'flex-start',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  form: {
    marginTop: 0
  },
  formContainer: {
    [theme.breakpoints.up('d')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 3fr',
      gap: theme.spacing(3),
    }
  },
  addEquipmentList: {
    [theme.breakpoints.up('d')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      columnGap: theme.spacing(3),
    }
  },
  subtitle: {
    marginTop: theme.spacing(2),
    borderBottom: '1px solid black',
    marginBottom: 0,
    fontWeight: 'bold',
  },
  field: {
    marginRight: 0,
    minWidth: 300,
    maxWidth: 400,
    marginBottom: 0,
    width: '100%',
  },
  toolbarSecondary: {
    justifyContent: 'flex-start',
    overflowX: 'auto',
  },
  toolbarSecondaryButtons: {
    marginLeft: theme.spacing(4)
  },
  buttonSubmit: {
    marginTop: theme.spacing(2)
  }
}));

function EditOrder() {
  const history = useHistory();
  const classes = useStyles();
  let params = useParams();
  const location = useLocation()
  const dispatch = useDispatch()

  const [order, setOrder] = useState((location.state !== undefined && location.state.order !== undefined && location.state.order) ? location.state.order : {})
  const [equipment, setEquipment] = useState(location.state !== undefined && location.state.equipment !== undefined && location.state.equipment ? location.state.equipment : [])
  const [loaded, setLoaded] = useState(false)
  const [loaded2, setLoaded2] = useState(false)
  const [error, setError] = useState(false)

  const passengerSeats = useSelector(state => state.dictionary.passengerSeats)
  const sleepCabinTypes = useSelector(state => state.dictionary.sleepCabinTypes)
  const sleepCabinBeds = useSelector(state => state.dictionary.sleepCabinBeds)
  const additionalEquipment = useSelector(state => state.dictionary.additionalEquipment)

  const [cabin, setCabin] = useState(0)
  const [equipmentForm, setFormEquipment] = useState([])

  useEffect(() => {
    dispatch(setName('Edytuj zamówienie'))
  }, [])

  const handleChangeEqupment = (e, el) => {
    const checked = e.target.form.querySelectorAll('[name="additional_equipment_id"]:checked')
    setFormEquipment([])
    checked.forEach(input => {
      setFormEquipment(state => [...state, input.value])
    })
  }

  useEffect(() => {
    setLoaded(true)
    setLoaded2(true)
    callAPI({
      url: `/api/v1/orders/${params.id}`,
      method: 'GET'
    })
      .then(res => {
        setOrder(res.data)
        setCabin(res.data.cabin ? res.data.cabin.uuid : 0)
        setLoaded(false)
      })
        .catch(() => {
          setLoaded(false)
          setError(true)
        })
    callAPI({
      url: `/api/v1/orders/${params.id}/equipments`,
      method: 'GET'
    })
      .then(res => {
        setEquipment(res.data)
        setFormEquipment(res.data.map(el => String(el.additional_equipment.uuid)))
        setLoaded2(false)
      })
        .catch(() => {
          setLoaded2(false)
          setError(true)
        })
  }, [params.id])

  const handleSubmit = (e) => {
    e.preventDefault()
    const data = new FormData(e.target)

    const equpment = data.getAll('additional_equipment_id')
    let dataEqupment = equpment.map(el => {
      return ({
        additional_equipment_id: Number(el),
        comment: data.get(`comment-${el}`)
      })
    })
    data.delete('additional_equipment_id')
    equipmentForm.forEach(el => {
      data.delete(`comment-${el}`)
    })
    const jsonData = Object.fromEntries(data.entries())
    if (jsonData.passenger_seats_id) {
      jsonData.passenger_seats_id = Number(jsonData.passenger_seats_id)
    } else {
      jsonData.passenger_seats_id = null
    }
    if (jsonData.sleep_cabin_beds_id) {
      jsonData.sleep_cabin_beds_id = Number(jsonData.sleep_cabin_beds_id)
    } else {
      jsonData.sleep_cabin_beds_id = null
    }
    if (jsonData.sleep_cabin_types_id) {
      jsonData.sleep_cabin_types_id = Number(jsonData.sleep_cabin_types_id)
    } else {
      jsonData.sleep_cabin_types_id = null
    }
    if (jsonData.status) {
      jsonData.status = Number(jsonData.status)
    } else {
      jsonData.status = null
    }
    if (jsonData.status_client) {
      jsonData.status_client = Number(jsonData.status_client)
    } else {
      jsonData.status_client = null
    }

    const update = () => {
      callAPI({
        url: `/api/v1/orders/${params.id}`,
        method: 'PATCH',
        data: jsonData
      }).then(res => {
        let counter = 0
        const countermax = dataEqupment.length
        if (res.data.uuid) {
          dataEqupment.forEach(de => {
            callAPI({
              url: '/api/v1/orders-equipment',
              method: 'POST',
              data: {
                ...de,
                order_id: res.data.uuid,
                price: ''
              }
            })
              .then(() => {
                counter += 1
                if (countermax === counter) {
                  dispatch(setNoticeSuccess('Zamówienie zmienione poprawnie'))
                  history.push(`/detail/${res.data.uuid}`)
                }
              })
          })
          if (countermax == 0) {
            dispatch(setNoticeSuccess('Zamówienie zmienione poprawnie'))
            history.push(`/detail/${res.data.uuid}`)
          }
        }
      })
    }

    let counterdel = 0
    const countermaxdel = equipment.length
    if (countermaxdel === 0) {
      update()
    }
    equipment.forEach(el => {

      callAPI({
        url: `/api/v1/orders-equipment/${el.uuid}`,
        method: 'DELETE'
      })
        .then(() => {
          counterdel += 1
          if (countermaxdel === counterdel) {
            update()
          }
        })
    })

    return false
  }

  return (
    <>
      <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
        <Typography variant="body2">Wróć do:</Typography>
        <Button className={classes.toolbarSecondaryButtons} color="primary" onClick={() => history.push("/")}>Lista zamówień</Button>
        <Button className={classes.toolbarSecondaryButtons} color="primary" onClick={() => history.push(`/detail/${params.id}`, {order, equipment})}>Szczegóły zamówienia</Button>
      </Toolbar>

      <Container maxWidth={false}>
        {order.uuid !== undefined && (
          <>
          <Paper className={classes.paper}>
            {error && (
                <Typography variant="body1" color="error">
                  Błąd pobierania zamówienia. Spróbuj jescze raz.
                </Typography>
            )}
            {loaded && (
                <>
                  <Skeleton variant="rect" width={'40%'} height={100} style={{marginBottom: 10}} />
                  <Skeleton variant="rect" width={'40%'} height={30} />
                </>
            )}
            <Typography variant="h4" component="h1">
              Edytuj zamówienie nr. {order.order_number} - {order.cabin.name}
            </Typography>
            <Typography variant="body1" className={classes.title}>
              Data zamówienia {dateInfo(order.created_at)}
            </Typography>
            <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
              <div className={classes.formContainer}>
                <div>
                  <div>
                    <TextField name="manual_order_number" defaultValue={order.manual_order_number}
                               label="Dodatkowy numer zamówienia" className={classes.field}/>
                  </div>
                  <Typography variant="subtitle1" className={classes.subtitle}>
                    Dane klienta
                  </Typography>
                  <div>
                    <TextField name="client_name" required defaultValue={order.client_name} label="Nazwa klienta"
                               className={classes.field}/>
                    <TextField name="client_address" defaultValue={order.client_address} label="Numer telefonu"
                               className={classes.field}/>
                    <TextField name="client_nip" defaultValue={order.client_nip} label="NIP klienta"
                               className={classes.field}/>
                    {/*<TextField name="client_regon" defaultValue={order.client_regon} label="Regon klienta" className={classes.field} />*/}
                  </div>
                  <Typography variant="subtitle1" className={classes.subtitle}>
                    Informacje o realizacji zamówienia
                  </Typography>
                  <div>
                    <TextField name="place_assembly" defaultValue={order.place_assembly} label="Miejsce montażu"
                               className={classes.field}/>
                    <TextField name="order_completion_time"
                               defaultValue={order.order_completion_time ? order.order_completion_time.substring(0, 10) : ''}
                               inputProps={{
                                 type: 'date',
                               }}
                               InputLabelProps={{
                                 shrink: true,
                               }}
                               label="Termin realizacji zamówienia" className={classes.field}/>
                  </div>
                  <Typography variant="subtitle1" className={classes.subtitle}>
                    Dodatkowe informacje
                  </Typography>
                  <div>
                    <TextField name="total_price" defaultValue={order.total_price} label="Lączna kwota zamówienia"
                               className={classes.field}/>
                    <TextField name="comment"
                               multiline
                               maxRows={5}
                               defaultValue={order.comment} label="Uwagi do zamówienia"
                               className={classes.field}/>
                  </div>
                  <Typography variant="subtitle1" className={classes.subtitle}>
                    Status zamówienia
                  </Typography>
                  <div>
                    <FormControl>
                      <InputLabel id="status">Status</InputLabel>
                      <Select name="status" defaultValue={order.status} labelId="status" className={classes.field}>
                        {statusList.map(el => (
                            <MenuItem key={el} value={el}>{status(el)}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <InputLabel id="status_client">Status realizacji</InputLabel>
                      <Select name="status_client" defaultValue={order.status_client || 4} labelId="status_client" className={classes.field}>
                        {statusListClient.map(el => (
                            <MenuItem key={el} value={el}>{status(el)}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div>
                  <Typography variant="subtitle1" className={classes.subtitle}>
                    Dane samochodu
                    - <strong>{order.brand && order.brand.name !== undefined ? order.brand.name : ''} - {order.color && order.color.name !== undefined ? order.color.name : ''}</strong>
                  </Typography>
                  <div>
                    <TextField name="production_year" defaultValue={order.production_year} label="Rok produkcji"
                               className={classes.field}/>
                    <TextField name="color_number" defaultValue={order.color_number} label="Numer lakieru"
                               className={classes.field}/>
                    <TextField name="color_car" defaultValue={order.color_car} label="Kolor samochodu"
                               className={classes.field}/>
                    <TextField name="vin" defaultValue={order.vin} label="VIN" className={classes.field}/>
                  </div>
                  <div>
                    {passengerSeats.length === 0 && sleepCabinTypes.length === 0 && sleepCabinBeds.length === 0 && (
                        <>
                          <Skeleton variant="rect" width={'100%'} height={80} style={{marginBottom: 10}} />
                        </>
                    )}
                    {(passengerSeats.find(el => el.cabin.uuid === cabin) || sleepCabinTypes.find(el => el.cabin.uuid === cabin) || sleepCabinBeds.find(el => el.cabin.uuid === cabin)) && (
                        <>
                          <Typography variant="subtitle1" className={classes.subtitle}>
                            Szczegóły dotyczące wybranej kabiny
                          </Typography>
                          <div>
                            {passengerSeats.length === 0 && (
                                <>
                                  <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}} />
                                </>
                            )}
                            {passengerSeats.find(el => el.cabin.uuid === cabin) && (
                                <>
                                  <FormControl>
                                    <InputLabel id="cabins-types">Fotel pasażera</InputLabel>
                                    <Select name="passenger_seats_id"
                                            defaultValue={order.passenger_seat ? order.passenger_seat.uuid : ''}
                                            labelId="cabins-types" className={classes.field}>
                                      {passengerSeats.filter(el => el.cabin.uuid === cabin).map(el => (
                                          <MenuItem key={el.uuid} value={el.uuid}>{el.name}</MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </>
                            )}
                            {sleepCabinTypes.length === 0 && (
                                <>
                                  <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}} />
                                </>
                            )}
                            {sleepCabinTypes.find(el => el.cabin.uuid === cabin) && (
                                <>
                                  <FormControl>
                                    <InputLabel id="cabins-types">Kabina sypialna</InputLabel>
                                    <Select name="sleep_cabin_types_id"
                                            defaultValue={order.sleep_cabin_type ? order.sleep_cabin_type.uuid : ''}
                                            labelId="cabins-types" className={classes.field}>
                                      {sleepCabinTypes.filter(el => el.cabin.uuid === cabin).map(el => (
                                          <MenuItem key={el.uuid} value={el.uuid}>{el.name}</MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </>
                            )}
                            {sleepCabinBeds.length === 0 && (
                                <>
                                  <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}} />
                                </>
                            )}
                            {sleepCabinBeds.find(el => el.cabin.uuid === cabin) && (
                                <>
                                  <FormControl>
                                    <InputLabel id="cabins-types">Kabina sypialna</InputLabel>
                                    <Select name="sleep_cabin_beds_id"
                                            defaultValue={order.sleep_cabin_bed ? order.sleep_cabin_bed.uuid : ''}
                                            labelId="cabins-types" className={classes.field}>
                                      {sleepCabinBeds.filter(el => el.cabin.uuid === cabin).map(el => (
                                          <MenuItem key={el.uuid} value={el.uuid}>{el.name}</MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </>
                            )}
                          </div>
                        </>
                    )}
                  </div>
                </div>
                <div>
                  {loaded2 || additionalEquipment.length === 0 && (
                      <>
                        <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}} />
                        <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}} />
                        <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}} />
                        <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}} />
                      </>
                  )}
                  {additionalEquipment.find(el => el.cabin.uuid === cabin) && (
                      <>
                        <Typography variant="subtitle1" className={classes.subtitle}>
                          Wyposażenie dodatkowe dla wybranej kabiny
                        </Typography>
                        <FormGroup className={classes.addEquipmentList}>
                          {additionalEquipment.filter(el => el.cabin.uuid === cabin).map(el => (
                              <div key={el.uuid}>
                                <FormControlLabel
                                    key={el.uuid}
                                    control={<Checkbox name="additional_equipment_id" value={el.uuid}
                                                       checked={equipmentForm.find(e => e === String(el.uuid)) ? true : false}/>}
                                    onChange={(e) => handleChangeEqupment(e, el)}
                                    label={
                                      `${el.name}${compareDate('2025-01-01T00:00:00', order.created_at) > 0 ? ' - ' + el.price + 'zł' : ''}${el.comment ? ', ' + el.comment : ''}`
                                    }/>
                                {(equipmentForm.find(e => e === String(el.uuid))) && (
                                    <TextField inputProps={{
                                      readOnly: equipment.find(eq => eq.additional_equipment.uuid === el.uuid) !== undefined ? true : false
                                    }} name={`comment-${el.uuid}`}
                                               defaultValue={equipment.find(eq => eq.additional_equipment.uuid === el.uuid) !== undefined ? equipment.find(eq => eq.additional_equipment.uuid === el.uuid).comment : ''}
                                               label="Dodatkowe informacje" className={classes.field}/>
                                )}
                              </div>
                          ))}
                        </FormGroup>
                      </>
                  )}
                </div>
              </div>






                {/*<Typography variant="subtitle1" className={classes.subtitle}>
              Etapy realizacji zamówienia
            </Typography>
            <div>
              <TextField name="laminated" defaultValue={order.laminated ? order.laminated.substring(0,10) : ''} inputProps={{
                type: 'date'
              }}
                         InputLabelProps={{
                           shrink: true,
                         }}
                         label="Wylaminowana" className={classes.field} />
              <TextField name="outcropped" defaultValue={order.outcropped ? order.outcropped.substring(0,10) : ''}
                         inputProps={{
                           type: 'date'
                         }}
                         InputLabelProps={{
                           shrink: true,
                         }}
                         label="Wykostkowana" className={classes.field} />
              <TextField name="upholstered" defaultValue={order.upholstered ? order.upholstered.substring(0,10) : ''}
                         inputProps={{
                           type: 'date'
                         }}
                         InputLabelProps={{
                           shrink: true,
                         }}
                         label="Wytapicerowana" className={classes.field} />
              <TextField name="varnishing" defaultValue={order.varnishing ? order.varnishing.substring(0,10) : ''}
                         inputProps={{
                           type: 'date'
                         }}
                         InputLabelProps={{
                           shrink: true,
                         }}
                         label="Lakierowanie" className={classes.field} />
              <TextField name="ready_to_assembly" defaultValue={order.ready_to_assembly ? order.ready_to_assembly.substring(0,10) : ''}
                         inputProps={{
                           type: 'date'
                         }}
                         InputLabelProps={{
                           shrink: true,
                         }}
                         label="Gotowa do montażu" className={classes.field} />
              <TextField name="assembly" defaultValue={order.assembly ? order.assembly.substring(0,10) : ''}
                         inputProps={{
                           type: 'date'
                         }}
                         InputLabelProps={{
                           shrink: true,
                         }}
                         label="Montaż" className={classes.field} />
            </div>*/}
                <Button type="submit" className={classes.buttonSubmit} variant="contained">Zapisz zmiany</Button>
            </form>
          </Paper>
          </>
          )}
      </Container>
    </>
  )

}

export default EditOrder
