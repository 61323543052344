import React, {useState, useRef, useEffect} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import {makeStyles} from '@material-ui/core/styles';
import {callAPI, compareDate, dateInfo, status, statusListClient} from "../../fuction";
import { useSelector, useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
    field: {
        marginRight: 0,
        minWidth: 300,
        maxWidth: 400,
        marginBottom: 20,
        width: '100%',
    },
}))
export const Modal = ({open, item, handleClose, onUpdate = () => null}) => {
    const classes = useStyles();
    const [order, setOrder] = useState(item)
    const cabinTypes = useSelector(state => state.dictionary.cabins)
    const brands = useSelector(state => state.dictionary.brands)
    const form = useRef(null)

    const handleSubmit = (e) => {
        e.preventDefault()
        const data = new FormData(form.current)
        const jsonData = Object.fromEntries(data.entries())
        if (jsonData.status_client) {
            jsonData.status_client = Number(jsonData.status_client)
        } else {
            jsonData.status_client = null
        }

        if (jsonData.cabin_id) {
            jsonData.cabin_id = Number(jsonData.cabin_id)
        } else {
            jsonData.cabin_id = null
        }
        if (jsonData.brand_id) {
            jsonData.brand_id = Number(jsonData.brand_id)
        } else {
            jsonData.brand_id = null
        }
        callAPI({
            url: `/api/v1/orders/${item.uuid}/fast`,
            method: 'PATCH',
            data: jsonData
        }).then(resp => {
            onUpdate(resp.data)
            setOrder(resp.data)
            handleClose()
        })
    }

    const handleCloseModal = () => {
        setOrder(null)
        handleClose()
    }

    useEffect(() => {
        setOrder(item)
    }, [item]);

    return (
        <>
            <Dialog open={open} onClose={handleCloseModal}>
                <DialogTitle>Szybka edycja zamówienia dla {item.client_name}</DialogTitle>
                <DialogContent>
                    {order && (
                        <form autoComplete="off" onSubmit={handleSubmit} ref={form}>
                            <TextField name="order_completion_time"
                                       defaultValue={order.order_completion_time ? order.order_completion_time.substring(0, 10) : ''}
                                       inputProps={{
                                           type: 'date',
                                       }}
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                                       label="Termin realizacji zamówienia" className={classes.field}/>

                            <TextField name="comment"
                                       multiline
                                       maxRows={5}
                                       defaultValue={order.comment} label="Uwagi do zamówienia"
                                       className={classes.field}/>

                            <FormControl className={classes.field}>
                                <InputLabel id="status_client">Status</InputLabel>
                                <Select name="status_client" defaultValue={order.status_client || 4} labelId="status_client">
                                    {statusListClient.map(el => (
                                        <MenuItem key={el} value={el}>{status(el)}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {cabinTypes !== undefined && cabinTypes.length > 0 && (
                                <FormControl className={classes.field}>
                                    <InputLabel required id="cabins-types">Typ kabiny</InputLabel>
                                    <Select aria-describedby={'cabin_types_text'} required defaultValue={order.cabin.uuid} name="cabin_id" labelId="cabins-types">
                                        {cabinTypes.map(el => (
                                            <MenuItem key={el.uuid} value={el.uuid}>{el.name}</MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText id={"cabin_types_text"}>
                                        Pamiętaj! Każdy typ kabiny posiada inne opcje w "Szczegóły dotyczące wybranej kabiny" oraz "Wyposażenie dodatkowe dla wybranej kabiny". Jeśli zmienisz typ kabiny, te opcje należy ustawić jeszcze raz w edycji zamówienia.
                                    </FormHelperText>
                                </FormControl>
                            )}
                            {brands !== undefined && brands.length > 0 && (
                                <FormControl className={classes.field}>
                                    <InputLabel required id="brand_id">Marka samochodu</InputLabel>
                                    <Select required defaultValue={order.brand.uuid} name="brand_id" labelId="brand_id">
                                        {brands.map(el => (
                                            <MenuItem key={el.uuid} value={el.uuid}>{el.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </form>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Auluj
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Zapisz
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}