import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {useDispatch, useSelector} from 'react-redux'
import {Link, useHistory} from "react-router-dom";
import {callAPI} from "../fuction";
import {setNoticeSuccess} from "../store/notistack";
import {resetUser} from "../store/user";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function AppBarCustom() {

  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch()
  const currentPage = useSelector(state => state.currentPage.name)

  const handleClickLogout = () => {

    callAPI({
      url: '/api/v1/logout',
      method: 'DELETE',
    })
      .then(() => {
        sessionStorage.removeItem('wejkamaAT')
        dispatch(setNoticeSuccess('Wylogowano poprawnie'))
        dispatch(resetUser())
        history.push("/login");
      })
      .catch(() => {
        sessionStorage.removeItem('wejkamaAT')
        dispatch(setNoticeSuccess('Wylogowano poprawnie'))
        dispatch(resetUser())
        history.push("/login");
      })

  }

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              {currentPage}
            </Typography>
            <Typography variant="h5" className={classes.title}>
              Wejkama
            </Typography>
            <Button color="inherit" component={Link} to="/new-order">Nowe zamówienie</Button>
            <Button color="inherit" component={Link} to="/callendar">Kalendarz</Button>
            <Button color="inherit" component={Link} to="/">Lista zamowień</Button>
            <Button color="secondary" onClick={handleClickLogout}>Wyloguj</Button>
          </Toolbar>
        </AppBar>
      </div>
    </>
  )
}