import React, {useEffect} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import SignIn from "./pages/user/Login";
import ForgotPassword from "./pages/user/ForgotPassword";
import ResetPassword from "./pages/user/ResetPassword";
import { useSelector } from 'react-redux'
import {useDispatch} from "react-redux";
import {getUserData, getDictionaryData} from "./saga/sagaActions";
import OrderList from "./pages/orders/List";
import AppBarCustom from "./components/AppBarCustom";
import { makeStyles } from '@material-ui/core/styles';
import NewOrder from "./pages/orders/New";
import DetailOrder from "./pages/orders/Detail";
import PdfGenerator from "./pages/orders/Pdf";
import EditOrder from "./pages/orders/Edit";
import Callendar from "./pages/callendar";

const useStyles = makeStyles((theme) => ({
  back: {
    background: theme.palette.background.default,
    minHeight: '100vh'
  }
}));

export default function Auth() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact  path="/login">
            <SignIn />
          </Route>
          <Route exact  path="/forgot-passwrord">
            <ForgotPassword />
          </Route>
          <Route exact  path="/auth/reset-password/:hash">
            <ResetPassword />
          </Route>
          <PrivateRoute  path="/callendar">
            <Callendar />
          </PrivateRoute>
          <PrivateRoute  path="/new-order">
            <NewOrder />
          </PrivateRoute>
          <PrivateRoute  path="/detail/:id">
            <DetailOrder />
          </PrivateRoute>
          <PrivateRoute  path="/edit/:id">
            <EditOrder />
          </PrivateRoute>
          <PrivateRoute  path="/generate-pdf/:id">
              <PdfGenerator />
          </PrivateRoute>
          <PrivateRoute path="/">
            <OrderList />
          </PrivateRoute>
        </Switch>
      </Router>
    </>
  );
}

function PrivateRoute({ children, ...rest }) {
  const classes = useStyles();
  const user = useSelector((state) => state.user)
  // const dictionary = useSelector((state) => state.dictionary)
  const dispatch = useDispatch()

  //TODO: Do usunięcia w przysłości
  /*useEffect(() => {
    console.log(dictionary)
  }, [dictionary])*/

  useEffect(() => {
    if (user.email === '') {
      dispatch(getUserData())
      dispatch(getDictionaryData())
    }
  }, [user, dispatch])


  return (
    <Route
      {...rest}
      render={({ location }) =>
        sessionStorage.getItem('wejkamaAT') ? (
          <>
            <div className={classes.back}>
              <AppBarCustom />
              {children}
            </div>
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
