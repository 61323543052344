import {createSlice} from "@reduxjs/toolkit";

const filters = createSlice({
  name: 'filters',
  initialState: {
    page: 0,
    limit: 25,
    dateOrder: 0,
    nameOrder: 0,
    clientAddress: '',
    statusFilter: '',
    cabinFilter: '',
    brandFilter: '',
    nipFilter: '',
    nameFilter: '',
    dateFilter: '',
    orderNumber: '',
    orderCompletionTime: '',
    orderCompletionMonth: '',
    month: new Date(),
  },
  reducers: {
    setPage: (state, action) => {
      return {
        ...state,
        page: action.payload,
      };
    },
    setLimit: (state, action) => {
      return {
        ...state,
        limit: action.payload,
      };
    },
    setDateOrder: (state, action) => {
      return {
        ...state,
        dateOrder: action.payload,
      };
    },
    setNameOrder: (state, action) => {
      return {
        ...state,
        nameOrder: action.payload,
      };
    },
    setClientAddress: (state, action) => {
      return {
        ...state,
        clientAddress: action.payload,
      };
    },
    setStatusFilter: (state, action) => {
      return {
        ...state,
        statusFilter: action.payload,
      };
    },
    setCabinFilter: (state, action) => {
      return {
        ...state,
        cabinFilter: action.payload,
      };
    },
    setBrandFilter: (state, action) => {
      return {
        ...state,
        brandFilter: action.payload,
      };
    },
    setNipFilter: (state, action) => {
      return {
        ...state,
        nipFilter: action.payload,
      };
    },
    setNameFilter: (state, action) => {
      return {
        ...state,
        nameFilter: action.payload,
      };
    },
    setDateFilter: (state, action) => {
      return {
        ...state,
        dateFilter: action.payload,
      };
    },
    setOrderNumber: (state, action) => {
      return {
        ...state,
        orderNumber: action.payload,
      };
    },
    setOrderCompletionTime: (state, action) => {
      return {
        ...state,
        orderCompletionTime: action.payload,
      };
    },
    setOrderCompletionMonth: (state, action) => {
      return {
        ...state,
        orderCompletionMonth: action.payload,
      };
    },
    setMonth: (state, action) => {
      return {
        ...state,
        month: action.payload,
      };
    },
  }
})
export const {
  setPage,
  setLimit,
  setAll,
  setDateOrder,
  setNameOrder,
  setClientAddress,
  setStatusFilter,
  setCabinFilter,
  setBrandFilter,
  setNipFilter,
  setNameFilter,
  setDateFilter,
  setOrderNumber,
  setOrderCompletionTime,
  setOrderCompletionMonth,
  setMonth,
} = filters.actions;
export default filters.reducer
