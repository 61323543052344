import React, {useEffect, useState} from 'react';
import {callAPI} from "../../fuction";
import {
    Paper,
    Typography,
    Container,
    Button,
    TableContainer,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {setName} from "../../store/currentPage";
import {
    setMonth
} from "../../store/filters";
import Grid from '@material-ui/core/Grid';
import {Item} from "./Item";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        paddingBottom: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        // width: '100%',
        minWidth: 'fit-content',
        marginBottom: theme.spacing(3),
    },
    containerPaper: {
        // width: '100%',
        // overflowX: 'auto',
        paddingBottom: theme.spacing(8),
    },
    filter: {
        display: "flex",
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-end'
    },
    sort: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        marginTop: '10px'
    },
    headrow: {
        backgroundColor: '#3f51b5',
        color: 'white',
        fontWeight: 'bold'
    },
    input: {
        width: '100%',
    },
    changemonth: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    tablePaper: {
        height: '100%',
    }
}));

const displayData = (data) => {
    const dataObject = new Date(data)
    return `${dataObject.getDate() < 10 ? '0' : ''}${dataObject.getDate()}-${dataObject.getMonth() < 9 ? '0' : ''}${dataObject.getMonth() + 1}-${dataObject.getFullYear()}`
}

const displayMonth = (data) => {
    const dataObject = new Date(data)
    const months = ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"];
    return `${months[dataObject.getMonth()]} ${dataObject.getFullYear()}`;
}
const displayDataToFilters = (data) => {
    const dataObject = new Date(data)
    return `${dataObject.getFullYear()}-${dataObject.getMonth() < 9 ? '0' : ''}${dataObject.getMonth() + 1}-01`
}

export default function Callendar() {
    const classes = useStyles();
    const history = useHistory();
    const cabinTypes = useSelector(state => state.dictionary.cabins)
    const brands = useSelector(state => state.dictionary.brands)
    const dispatch = useDispatch()
    const filters = useSelector(state => state.filters)

    const [orders, setOrders] = useState([])
    const [ordersCalendar, setOrdersCalendar] = useState([])
    const [all, setAll] = useState(0)

    useEffect(() => {
        dispatch(setName('Kalendarz'))
    }, [dispatch])

    useEffect(() => {
        const params = new URLSearchParams();
        params.append('page', String(1));
        params.append('limit', String(10000));
        params.append('orderCompletionMonth', displayDataToFilters(filters.month));
        callAPI({
            url: '/api/v1/search-orders-paginated',
            method: 'GET',
            data: {},
            params
        })
            .then(resp => {
                setOrders(resp.data)
            })
    }, [filters])

    useEffect(() => {
        const disableCanceled = orders.filter(order => order.status !== 3)
        const groupedOrders = disableCanceled.reduce((acc, order) => {
            const date = order.order_completion_time ? order.order_completion_time.split('T')[0] : 'null';
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(order);
            return acc;
        }, {});

        const sortedGroupedOrders = Object.keys(groupedOrders)
            .sort((a, b) => a.localeCompare(b))
            .reduce((acc, key) => {
                acc[key] = groupedOrders[key];
                return acc;
            }, {});


        setOrdersCalendar(sortedGroupedOrders);
    }, [orders]);

    const onUpdate = (data) => {
        setOrders(orders.map(order => order.uuid === data.uuid ? data : order))
    }

    const handleChangeFilterDateCompletionMonth = (direction) => {
        const date = new Date(filters.month)
        if (direction === 'prev') {
            date.setMonth(date.getMonth() - 1)
        } else if (direction === 'next') {
            date.setMonth(date.getMonth() + 1)
        }
        dispatch(setMonth(date))
    }

    return (
        <>
            <Container className={classes.containerPaper} maxWidth="100%">
                <Paper className={classes.paper}>
                            <div className={classes.changemonth}>
                                <Button onClick={() => handleChangeFilterDateCompletionMonth('prev')}>Poprzedni miesiąc</Button>
                                <Typography variant="h6" component="h1" className={classes.title}>{displayMonth(filters.month)}</Typography>
                                <Button onClick={() => handleChangeFilterDateCompletionMonth('next')}>Następny miesiąc</Button>
                            </div>
                </Paper>
                    <Grid container spacing={3}>
                        {Object.keys(ordersCalendar).map((order, index) => (
                            <Grid item xs={12} lg={6} xl={4} key={index}>
                                <TableContainer component={Paper} className={classes.tablePaper}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} className={classes.headrow}>
                                                {order}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Klient</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Kabina</TableCell>
                                            <TableCell>Auto</TableCell>
                                            <TableCell>Info</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {ordersCalendar[order].map((row, key) => (
                                            <Item item={row} key={key} onUpdate={onUpdate} />
                                        ))}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                            </Grid>
                        ))}
                    </Grid>
            </Container>
        </>
    )
}